export default {
  email: "Email",
  password: "Heslo",
  log_in: "Přihlásit se",
  stay_signed_in: "Zůstat přihlášen",
  forgot_password: "Zapomněl jsem heslo",
  reset_password: "Resetovat heslo",
  reset_password_email: "Email na resetování hesla",
  resend_verification_email: "Znovu poslat verifikační email",
  title: "Přihlásit se",
  log_in_message: "Chci se přihlásit",
  login_button: "Přihlášení",
  or_use_social: "Nebo se přihlaste/registrujte přes",
  type_email: "Napište Váš email",
  type_password: "Napište Vaše heslo",
  registration_title: "Registrace",
  registration_message: "Chci se zaregistrovat",
  register_button: "Registrovat",
  password_again: "Heslo znovu",
  missing_password: "Heslo je povinné.",
  missing_email: "Email je povinný.",
  un_matched_passwords: "Hesla se neshodují.",
  password_too_short: "Heslo musí mít alespoň 6 znaků.",
  cancel: "Zrušit",
  registration_success_message: `Váš účet byl úspěšně vytvořen. Verifikační email byl zaslán na Vaši emailovou adresu.
  K dokončení registračního procesu, klikněte prosím na odkaz ve verifikačním emailu. UJISTĚTE SE, ŽE ZKONTROLUJETE SPAM!`,
  google_signin: "Přihlásit přes Google",
  facebook_signin: "Přihlásit přes Facebook",
  session_expired: "Přihlášení vypršelo, prosím přihlašte se znovu.",
  
  api_messages: {
    email_not_verified: "Vaše emailová adresa stále nebyla verifikována, pokud jste nedostal verifikační email, klikněte prosím zde:"
  },

  messages: {
    successful_password_reset: "Email pro resetování hesla byl úspěšně zaslán na Vaši emailovou adresu.",
    email_verification_resend: `Verifikační email byl zaslán na Vaši emailovou adresu.
    K dokončení verifikačního procesu, klikněte prosím na odkaz ve verifikačním emailu. UJISTĚTE SE, ŽE ZKONTROLUJETE SPAM!`
  }
}
